@use 'styles/colors';

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#nprogress {
  // make clicks pass through
  pointer-events: none;

  .bar {
    background: colors.$gold;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  // Fancy blur effect
  //.peg {
  //  display: block;
  //  position: absolute;
  //  right: 0;
  //  width: 100px;
  //  height: 100%;
  //  box-shadow: 0 0 10px colors.$gold, 0 0 5px colors.$gold;
  //  opacity: 1;
  //  -webkit-transform: rotate(3deg) translate(0, -4px);
  //  -ms-transform: rotate(3deg) translate(0, -4px);
  //  transform: rotate(3deg) translate(0, -4px);
  //}

  // Remove these to get rid of the spinner
  .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: colors.$gold;
    border-left-color: colors.$gold;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
  #nprogress {
    .spinner {
      position: absolute;
    }
    .bar {
      position: absolute;
    }
  }
}
