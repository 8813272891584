@use './_colors';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Raleway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  font-size: 16px;
  color: colors.$grey;
}

* {
  box-sizing: border-box;
}

a {
  color: colors.$gold;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

p {
  margin-top: 4px;
  margin-bottom: 12px;
}
